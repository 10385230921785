import { Abbreviations, Units } from '../../../constants/units-and-abbreviations';
import { formatPrice, getImageCropUrl, isNullOrEmpty } from '../../../utils/helpers';
import { hesselApiTypes } from '../../api/models/hessel-api';
import { hesselViewModels } from '../../view-models';
import { getFirstRegFormatted } from './mapper.helper';

export function mapProductDetailsList(productDetailsList?: Array<hesselApiTypes.CarDetails>): Array<hesselViewModels.ProductDetails> | undefined {
    if (!productDetailsList) {
        return undefined;
    }

    const mappedList: Array<hesselViewModels.ProductDetails> = productDetailsList.map((productDetails) => {
        const isElBil = productDetails.specification.fuelType.toLowerCase() === 'el';
        const isHybrid = productDetails.specification.fuelType.toLowerCase().includes('hybrid');
        const isVan = productDetails.categoryTypeCode === 'v';
        return {
            id: productDetails.vehicleId,
            url: productDetails.url,
            vehicleType: productDetails.category as hesselViewModels.VehicleType, // Category
            itemNumber: productDetails?.itemNumber || productDetails.vehicleId,
            referenceNumber: productDetails.referenceNumber,
            variantId: productDetails.variantId,
            entityId: productDetails.entityId,
            description: productDetails.description,
            carType: productDetails.bodyType as hesselViewModels.CarType, // BodyType
            fuelType: productDetails.specification.fuelType,
            fuelTypeAbbreviation: productDetails.specification.fuelTypeAbbreviation,
            transmissionType: productDetails.specification.transmissionType,
            tractionWheels: productDetails.specification.tractionWheels,
            batteryCapacity: productDetails.specification.batteryCapacity,
            range: productDetails.specification.range,
            availability: productDetails.availability as hesselViewModels.VehicleAvailability,
            brand: productDetails.brand as hesselViewModels.Brands,
            brandTitle: `${productDetails.brand}, ${productDetails.modelTitle}`,
            modelTitle: productDetails.modelTitle,
            height: productDetails.specification.height,
            heightType: productDetails.specification.heightType,
            lengthType: productDetails.specification.lengthType,
            length: productDetails.specification.length,
            energyRating: productDetails.specification.energyLabel as hesselViewModels.EnergyRating,
            highlightedSpecs: getHighlightedSpecs(productDetails),
            leasingDurationsMonths: [],
            payment: 0,
            pricePerMonth: 808,
            specificationGroups: [
                {
                    heading: 'Køretøj',
                    specifications: [
                        { label: 'Biltype', value: `${productDetails.bodyType ?? '-'}` },
                        {
                            label: 'Grøn ejerafgift',
                            value: !isNullOrEmpty(productDetails.specification.greenOwnerFee)
                                ? `${formatPrice(+productDetails.specification.greenOwnerFee, 0)} ${Abbreviations.KR}/${
                                      productDetails.availability === 'Engros' || productDetails.availability === 'Used' ? '12' : '6'
                                  } ${Abbreviations.MDR}`
                                : '-',
                        },
                        {
                            label: 'Forventet leveringstid',
                            value: '{{deliveryPlaceholder}}',
                        },
                        productDetails.availability === 'Used' || productDetails.availability === 'Engros'
                            ? { ...getModelYearSpec(productDetails) }
                            : null,
                        productDetails.availability === 'Used' || productDetails.availability === 'Engros'
                            ? {
                                  ...getFirstRegSpec(productDetails),
                              }
                            : null,
                        productDetails.availability === 'Used' || productDetails.availability === 'Engros'
                            ? {
                                  label: 'Kilometer stand',
                                  value: formatPrice(productDetails.specification.odometerReadingInKm ?? '') ?? '-',
                              }
                            : null,
                    ],
                    images: [],
                },
                {
                    heading: 'Motor',
                    specifications: [
                        {
                            label: 'Hestekræfter',
                            value: productDetails.specification.horsePower ? `${productDetails.specification.horsePower} hk.` : '-',
                        },
                        {
                            label: 'Drejningsmoment',
                            value:
                                productDetails.specification.newtonMeter && productDetails.specification.newtonMeter.length > 0
                                    ? `${productDetails.specification.newtonMeter} nm.`
                                    : '-',
                            unit: '',
                        },
                        { ...getRangeSpec(productDetails) },

                        {
                            label: 'Co2',
                            value: `${formatPrice(productDetails.specification.co2Emission)} ${Abbreviations.G_SLASH_KM}`,
                        },
                        {
                            label: 'Tophastighed',
                            value: productDetails.specification.topSpeed
                                ? `${formatPrice(productDetails.specification.topSpeed)} ${Abbreviations.KM_SLASH_T}`
                                : '-',
                        },
                        {
                            label: `0 - 100 ${Abbreviations.KM_SLASH_T}`,
                            value: productDetails.specification.zeroToOneHundred
                                ? `${formatPrice(productDetails.specification.zeroToOneHundred, 1)} s.`
                                : '-',
                        },
                        !isElBil ? { label: 'Cylindre', value: `${productDetails.specification.cylinders ?? '-'}` } : null,
                        !isElBil ? { label: 'Geartype', value: productDetails.specification.transmissionType } : null,
                        !isElBil
                            ? {
                                  label: 'Antal gear',
                                  value: productDetails.specification.numberOfGears > 0 ? `${productDetails.specification.numberOfGears} gear` : '-',
                              }
                            : null,
                        { label: 'Drivmiddel', value: productDetails.specification.fuelType },
                        isVan
                            ? {
                                  ...getPayloadSpec(productDetails),
                              }
                            : null,
                        {
                            ...getMaximumTowingWeight(productDetails),
                        },
                        {
                            label: 'Trækhjul',
                            value: !isNullOrEmpty(productDetails.specification.tractionWheels)
                                ? `${productDetails.specification.tractionWheels}`
                                : '-',
                        },
                        !isElBil && !isHybrid
                            ? {
                                  label: 'Energimærke',
                                  value: !isNullOrEmpty(productDetails.specification.energyLabel)
                                      ? `${productDetails.specification.energyLabel}`
                                      : '-',
                              }
                            : null,
                    ],
                    images: [],
                },
                {
                    heading: 'Mål og dimensioner',
                    specifications: [
                        {
                            label: 'A: Højde',
                            value: productDetails.specification.height > 0 ? `${formatPrice(productDetails.specification.height)} mm.` : '-',
                        },
                        {
                            label: 'B: Bredde',
                            value:
                                productDetails.specification.height > 0
                                    ? `${formatPrice(productDetails.specification.widthIncludingMirrors)} mm.`
                                    : '-',
                        },
                        {
                            label: 'C: Længde',
                            value: productDetails.specification.length > 0 ? `${formatPrice(productDetails.specification.length)} mm.` : '-',
                        },
                        {
                            label: 'D: Akselafstand',
                            value:
                                productDetails.specification.wheelbaseDistance > 0
                                    ? `${formatPrice(productDetails.specification.wheelbaseDistance)} mm.`
                                    : '-',
                        },
                        {
                            label: 'Bagagerumsvolume',
                            value: productDetails.specification.bootCapacity ? `${productDetails.specification.bootCapacity} l.` : '-',
                        },
                        {
                            label: 'Totalvægt',
                            value: productDetails.specification.totalWeight ? `${formatPrice(productDetails.specification.totalWeight, 0)} kg.` : '-',
                        },
                    ],
                    images: [],
                },
            ],
            variantTitle: productDetails.name,
            name: productDetails.name,

            location: productDetails.location,
            locationId: productDetails.locationId,

            purchaseTypes: { ...productDetails.purchaseTypes },
            campaigns: productDetails.campaigns,

            metaTitle: productDetails.metaTitle,
            metaDescription: productDetails.metaDescription,

            standardEquipmentPackages: {
                'Car HiRE': productDetails.equipmentPackages.filter((x) => x.hireEquipmentStatus === 'Standard'),
                'Car Privat Leasing': productDetails.equipmentPackages.filter((x) => x.privateLeasingEquipmentStatus === 'Standard'),
                'Car Finansiering': productDetails.equipmentPackages.filter((x) => x.cashEquipmentStatus === 'Standard'),
                'Car Kontant': productDetails.equipmentPackages.filter((x) => x.cashEquipmentStatus === 'Standard'),

                'Van Operationel Leasing': productDetails.equipmentPackages.filter((x) => x.operationalLeasingEquipmentStatus === 'Standard'),
                'Van Finansiel Leasing': productDetails.equipmentPackages.filter((x) => x.financialLeasingEquipmentStatus === 'Standard'),
                'Van Finansiering': productDetails.equipmentPackages.filter((x) => x.cashEquipmentStatus === 'Standard'),
                'Van Kontant': productDetails.equipmentPackages.filter((x) => x.cashEquipmentStatus === 'Standard'),

                'No Value': [],
            },

            extraEquipmentPackages: {
                'Car HiRE': productDetails.equipmentPackages
                    .filter((x) => x.hireEquipmentStatus === 'Extra' || x.hireEquipmentStatus === 'ExtraIncluded')
                    .map((y) => {
                        return {
                            ...y,
                            ownershipStatus: y.hireEquipmentStatus,
                        };
                    }),

                'Car Privat Leasing': productDetails.equipmentPackages
                    .filter(
                        (x) =>
                            x.privateLeasingEquipmentStatus === 'Extra' ||
                            x.privateLeasingEquipmentStatus === 'ExtraIncluded' ||
                            x.privateLeasingEquipmentStatus === 'ContactForPrice'
                    )
                    .map((y) => {
                        return {
                            ...y,
                            ownershipStatus: y.privateLeasingEquipmentStatus,
                        };
                    }),

                'Car Finansiering': productDetails.equipmentPackages
                    .filter((x) => x.cashEquipmentStatus === 'Extra' || x.cashEquipmentStatus === 'ExtraIncluded')
                    .map((y) => {
                        return {
                            ...y,
                            ownershipStatus: y.cashEquipmentStatus,
                        };
                    }),

                'Car Kontant': productDetails.equipmentPackages
                    .filter((x) => x.cashEquipmentStatus === 'Extra' || x.cashEquipmentStatus === 'ExtraIncluded')
                    .map((y) => {
                        return {
                            ...y,
                            ownershipStatus: y.cashEquipmentStatus,
                        };
                    }),

                'Van Operationel Leasing': productDetails.equipmentPackages
                    .filter(
                        (x) =>
                            x.operationalLeasingEquipmentStatus === 'Extra' ||
                            x.operationalLeasingEquipmentStatus === 'ExtraIncluded' ||
                            x.operationalLeasingEquipmentStatus === 'ContactForPrice'
                    )
                    .map((y) => {
                        return {
                            ...y,
                            ownershipStatus: y.operationalLeasingEquipmentStatus,
                        };
                    }),

                'Van Finansiel Leasing': productDetails.equipmentPackages
                    .filter(
                        (x) =>
                            x.financialLeasingEquipmentStatus === 'Extra' ||
                            x.financialLeasingEquipmentStatus === 'ExtraIncluded' ||
                            x.financialLeasingEquipmentStatus === 'ContactForPrice'
                    )
                    .map((y) => {
                        return {
                            ...y,
                            ownershipStatus: y.financialLeasingEquipmentStatus,
                        };
                    }),

                'Van Finansiering': productDetails.equipmentPackages
                    .filter((x) => x.cashEquipmentStatus === 'Extra' || x.cashEquipmentStatus === 'ExtraIncluded')
                    .map((y) => {
                        return {
                            ...y,
                            ownershipStatus: y.cashEquipmentStatus,
                        };
                    }),

                'Van Kontant': productDetails.equipmentPackages
                    .filter((x) => x.cashEquipmentStatus === 'Extra' || x.cashEquipmentStatus === 'ExtraIncluded')
                    .map((y) => {
                        return {
                            ...y,
                            ownershipStatus: y.cashEquipmentStatus,
                        };
                    }),

                'No Value': [],
            },

            standardEquipment: {
                'Car HiRE': productDetails.equipment.filter((x) => x.hireEquipmentStatus === 'Standard'),
                'Car Privat Leasing': productDetails.equipment.filter((x) => x.privateLeasingEquipmentStatus === 'Standard'),
                'Car Finansiering': productDetails.equipment.filter((x) => x.cashEquipmentStatus === 'Standard'),
                'Car Kontant': productDetails.equipment.filter((x) => x.cashEquipmentStatus === 'Standard'),

                'Van Operationel Leasing': productDetails.equipment.filter((x) => x.operationalLeasingEquipmentStatus === 'Standard'),
                'Van Finansiel Leasing': productDetails.equipment.filter((x) => x.financialLeasingEquipmentStatus === 'Standard'),
                'Van Finansiering': productDetails.equipment.filter((x) => x.cashEquipmentStatus === 'Standard'),
                'Van Kontant': productDetails.equipment.filter((x) => x.cashEquipmentStatus === 'Standard'),

                'No Value': [],
            },

            extraEquipment: {
                'Car HiRE': productDetails.equipment
                    .filter((x) => x.hireEquipmentStatus === 'Extra' || x.hireEquipmentStatus === 'ExtraIncluded')
                    .map((y) => {
                        return {
                            ...y,
                            ownershipStatus: y.hireEquipmentStatus,
                        };
                    }),

                'Car Privat Leasing': productDetails.equipment
                    .filter(
                        (x) =>
                            x.privateLeasingEquipmentStatus === 'Extra' ||
                            x.privateLeasingEquipmentStatus === 'ExtraIncluded' ||
                            x.privateLeasingEquipmentStatus === 'ContactForPrice'
                    )
                    .map((y) => {
                        return {
                            ...y,
                            ownershipStatus: y.privateLeasingEquipmentStatus,
                        };
                    }),

                'Car Finansiering': productDetails.equipment
                    .filter((x) => x.cashEquipmentStatus === 'Extra' || x.cashEquipmentStatus === 'ExtraIncluded')
                    .map((y) => {
                        return {
                            ...y,
                            ownershipStatus: y.cashEquipmentStatus,
                        };
                    }),

                'Car Kontant': productDetails.equipment
                    .filter((x) => x.cashEquipmentStatus === 'Extra' || x.cashEquipmentStatus === 'ExtraIncluded')
                    .map((y) => {
                        return {
                            ...y,
                            ownershipStatus: y.cashEquipmentStatus,
                        };
                    }),

                'Van Operationel Leasing': productDetails.equipment
                    .filter(
                        (x) =>
                            x.operationalLeasingEquipmentStatus === 'Extra' ||
                            x.operationalLeasingEquipmentStatus === 'ExtraIncluded' ||
                            x.operationalLeasingEquipmentStatus === 'ContactForPrice'
                    )
                    .map((y) => {
                        return {
                            ...y,
                            ownershipStatus: y.operationalLeasingEquipmentStatus,
                        };
                    }),

                'Van Finansiel Leasing': productDetails.equipment
                    .filter(
                        (x) =>
                            x.financialLeasingEquipmentStatus === 'Extra' ||
                            x.financialLeasingEquipmentStatus === 'ExtraIncluded' ||
                            x.financialLeasingEquipmentStatus === 'ContactForPrice'
                    )
                    .map((y) => {
                        return {
                            ...y,
                            ownershipStatus: y.financialLeasingEquipmentStatus,
                        };
                    }),

                'Van Finansiering': productDetails.equipment
                    .filter((x) => x.cashEquipmentStatus === 'Extra' || x.cashEquipmentStatus === 'ExtraIncluded')
                    .map((y) => {
                        return {
                            ...y,
                            ownershipStatus: y.cashEquipmentStatus,
                        };
                    }),

                'Van Kontant': productDetails.equipment
                    .filter((x) => x.cashEquipmentStatus === 'Extra' || x.cashEquipmentStatus === 'ExtraIncluded')
                    .map((y) => {
                        return {
                            ...y,
                            ownershipStatus: y.cashEquipmentStatus,
                        };
                    }),

                'No Value': [],
            },

            optionalEquipment: {
                'Car HiRE': productDetails.equipment.filter((x) => x.hireEquipmentStatus === 'Optional'),
                'Car Privat Leasing': productDetails.equipment.filter((x) => x.privateLeasingEquipmentStatus === 'Optional'),
                'Car Finansiering': productDetails.equipment.filter((x) => x.cashEquipmentStatus === 'Optional'),
                'Car Kontant': productDetails.equipment.filter((x) => x.cashEquipmentStatus === 'Optional'),

                'Van Operationel Leasing': productDetails.equipment.filter((x) => x.operationalLeasingEquipmentStatus === 'Optional'),
                'Van Finansiel Leasing': productDetails.equipment.filter((x) => x.financialLeasingEquipmentStatus === 'Optional'),
                'Van Finansiering': productDetails.equipment.filter((x) => x.cashEquipmentStatus === 'Optional'),
                'Van Kontant': productDetails.equipment.filter((x) => x.cashEquipmentStatus === 'Optional'),

                'No Value': [],
            },

            equipmentNames: productDetails.equipmentNames,

            resources: productDetails.resources.map((resource) => {
                switch (resource.type) {
                    case 'Image':
                        return {
                            id: resource.id,
                            type: 'image',
                            url: getImageCropUrl(resource.data, 'productdetailsfullsize'),
                            colorCode: resource.colorCode,
                        };

                    case 'YouTube':
                        return {
                            id: resource.id,
                            type: 'youTube',
                            baseUrl: 'https://www.youtube.com/embed/',
                            name: resource.name,
                            youTubeId: resource.data,
                        };

                    case 'Video':
                        return {
                            id: resource.id,
                            type: 'video',
                            url: getImageCropUrl(resource.data, 'productdetailsfullsize'),
                            colorCode: resource.colorCode,
                        };

                    default:
                        throw new Error('product-details.mapper.ts: Media type not supported');
                }
            }),
            color: productDetails.color,
            colors: productDetails.colors
                .filter((x) =>
                    productDetails.availability === 'Order'
                        ? (x.monthlyHirePrice !== undefined && x.monthlyHirePrice !== null) ||
                          (x.cashPrice !== undefined && x.cashPrice !== null) ||
                          (x.monthlyPrivateLeasingPrice !== undefined && x.monthlyPrivateLeasingPrice !== null) ||
                          (x.monthlyFinancialLeasingPrice !== undefined && x.monthlyFinancialLeasingPrice !== null) ||
                          (x.monthlyOperationalLeasingPrice !== undefined && x.monthlyOperationalLeasingPrice !== null)
                        : true
                )
                .sort((a, b) => {
                    if (!a.parentColorName || !b.parentColorName) {
                        return 0;
                    }

                    return a.parentColorName.localeCompare(b.parentColorName);
                })
                .map((c, index) => {
                    if (!c.resource) {
                        c.resource = {
                            id: `placeholder-id-${index}`,
                            name: `placeholder-name-${index}`,
                            data: '/png/crossed-circle.png',
                            colorCode: `placeholder-color-${index}`,
                            colorName: `placeholder-color-name-${index}`,
                            type: 'Image',
                            filename: 'placeholder.png',
                        };
                        return c;
                    }

                    c.resource.data = getImageCropUrl(c.resource.data, 'icon');
                    return c;
                }),
            variantModelCode: productDetails.variantModelCode,
            brochureUrl: productDetails.brochureUrl,
            starmarkLink: productDetails.starmarkUrl,
            fullEquipmentListUrl: productDetails.fullEquipmentListUrl,
            generalCampaignLabels: [
                {
                    id: 'gcl-1',
                    text: 'Gratis HesselPlus',
                    textColor: '231f20',
                    backgroundColor: '78c55c',
                },
                {
                    id: 'gcl-2',
                    text: 'Spar 10.000 kr',
                    textColor: 'fafafa',
                    backgroundColor: '489fdf',
                },
                {
                    id: 'gcl-3',
                    text: '0% i finansiering',
                    textColor: 'fafafa',
                    backgroundColor: '231f20',
                },
                {
                    id: 'gcl-4',
                    text: 'Plug-in hybrid',
                    textColor: 'fafafa',
                    backgroundColor: 'ed571c',
                },
            ],
            privateLeasing: {
                price: null,
            },
            autolineId: productDetails.locationId,
            equipmentLine: productDetails.equipmentLine,
            specifications: {
                consumption:
                    productDetails.specification.fuelType === 'El'
                        ? formatPrice(productDetails.specification.mixedDriving ?? 0, 1) + ` ${Units.kWh}/100 ${Units.km}`
                        : formatPrice(productDetails.specification.mixedDriving, 1) + ` ${Abbreviations.KM_SLASH_L}`,
                co2Emission: formatPrice(productDetails.specification.co2Emission, 0) + ` ${Abbreviations.G_SLASH_KM}`,
                greenOwnershipFee: productDetails.specification.greenOwnerFee,
                firstYearOfRegistration: productDetails.specification.firstYearOfRegistration,
                firstMonthAndYearOfRegistration: productDetails.specification.firstMonthAndYearOfRegistration,
                odometerReadingInKm: productDetails.specification.odometerReadingInKm,
            },
            canonicalUrl: productDetails.canonicalUrl,
            modelYear: productDetails.modelYear ?? '',
            testDrive: productDetails.testDrive === null ? 'Available' : productDetails.testDrive,
            discontinued: productDetails.discontinued,
        } as hesselViewModels.ProductDetails;
    });

    return mappedList;
}

function getFuelData(fuelType: string): { label: string; value: string } {
    return { label: 'Drivmiddel', value: fuelType };
}

export function getDeliveryTime(waitingDays: number): string {
    const months = Math.round(waitingDays / 30);
    if (months === 0) return 'Omgående levering';
    if (months === 1) return 'Ca. 1 måned';
    return `Ca. ${months} mdr.`;
}

const formatSpec = (value: string | number | undefined | null) => {
    if (!value) return '-';

    return value.toString();
};

const getRangeSpec = (productDetails: hesselApiTypes.CarDetails): hesselViewModels.MappedSpecification => {
    return {
        label: productDetails.specification.fuelType === 'El' ? 'Rækkevidde' : 'Forbrug',
        value:
            productDetails.specification.fuelType === 'El'
                ? formatSpec(formatPrice(productDetails.specification.range ?? 0, 0))
                : formatSpec(formatPrice(productDetails.specification.mixedDriving, 1)),
        unit: productDetails.specification.fuelType === 'El' ? Units.km : Abbreviations.KM_SLASH_L,
    };
};
const getHpSpec = (productDetails: hesselApiTypes.CarDetails): hesselViewModels.MappedSpecification => {
    return {
        label: 'Hestekræfter',
        value: formatSpec(productDetails.specification.horsePower),
        unit: Units.HP,
    };
};
const getTractionWheelSpec = (productDetails: hesselApiTypes.CarDetails): hesselViewModels.MappedSpecification => {
    return {
        label: 'Trækhjul',
        value: formatSpec(productDetails.specification.tractionWheels),
    };
};
const getFirstRegSpec = (productDetails: hesselApiTypes.CarDetails): hesselViewModels.MappedSpecification => {
    return {
        label: '1. registrering',
        value: formatSpec(getFirstRegFormatted(productDetails.specification.firstMonthAndYearOfRegistration)),
    };
};
const getOdometerReadingSpec = (productDetails: hesselApiTypes.CarDetails): hesselViewModels.MappedSpecification => {
    return {
        label: 'Kilometerstand',
        value: formatSpec(formatPrice(productDetails.specification.odometerReadingInKm)),
        unit: Units.km,
    };
};
const getMaximumTowingWeight = (productDetails: hesselApiTypes.CarDetails): hesselViewModels.MappedSpecification => {
    return {
        label: 'Trækvægt (max.)',
        value: formatSpec(formatPrice(productDetails.specification.maximumTowingWeight)),
        unit: Units.KG,
    };
};

const getModelYearSpec = (productDetails: hesselApiTypes.CarDetails): hesselViewModels.MappedSpecification => {
    return {
        label: 'Modelår',
        value: formatSpec(productDetails.modelYear),
    };
};
const getTransmissionSpec = (productDetails: hesselApiTypes.CarDetails): hesselViewModels.MappedSpecification => {
    return {
        label: 'Geartype',
        value: formatSpec(productDetails.specification.transmissionType),
    };
};

const getPayloadSpec = (productDetails: hesselApiTypes.CarDetails): hesselViewModels.MappedSpecification => {
    return {
        label: 'Nyttelast',
        value: formatSpec(formatPrice(productDetails.specification.payload)),
        unit: Units.KG,
    };
};
const getOrderCarSpecs = (productDetails: hesselApiTypes.CarDetails): hesselViewModels.MappedSpecification[] => {
    return [
        { ...getHpSpec(productDetails) },
        { ...getFuelData(productDetails.specification.fuelType) },
        {
            label: '0 - 100 km/t',
            value: formatPrice(productDetails.specification.zeroToOneHundred, 1),
            unit: Abbreviations.SEC,
        },
        { ...getRangeSpec(productDetails) },
        {
            label: 'Tophastighed',
            value: formatSpec(productDetails.specification.topSpeed),
            unit: Abbreviations.KM_SLASH_T,
        },
        { ...getTractionWheelSpec(productDetails) },
    ];
};
const getUsedCarSpecs = (productDetails: hesselApiTypes.CarDetails): hesselViewModels.MappedSpecification[] => {
    return [
        { ...getFirstRegSpec(productDetails) },
        { ...getModelYearSpec(productDetails) },
        { ...getOdometerReadingSpec(productDetails) },
        { ...getHpSpec(productDetails) },
        { ...getFuelData(productDetails.specification.fuelType) },
        { ...getRangeSpec(productDetails) },
    ];
};
const getUsedVanSpecs = (productDetails: hesselApiTypes.CarDetails): hesselViewModels.MappedSpecification[] => {
    return [
        { ...getFirstRegSpec(productDetails) },
        { ...getModelYearSpec(productDetails) },
        { ...getOdometerReadingSpec(productDetails) },
        { ...getHpSpec(productDetails) },
        { ...getFuelData(productDetails.specification.fuelType) },
        { ...getMaximumTowingWeight(productDetails) },
    ];
};
const getOrderVanSpecs = (productDetails: hesselApiTypes.CarDetails): hesselViewModels.MappedSpecification[] => {
    return [
        { ...getHpSpec(productDetails) },
        { ...getFuelData(productDetails.specification.fuelType) },
        { ...getRangeSpec(productDetails) },
        { ...getMaximumTowingWeight(productDetails) },
        { ...getTransmissionSpec(productDetails) },
        { ...getPayloadSpec(productDetails) },
    ];
};

const getHighlightedSpecs = (productDetails: hesselApiTypes.CarDetails): hesselViewModels.MappedSpecification[] => {
    if (productDetails.category === 'Car') {
        if (productDetails.availability === 'Used') {
            return getUsedCarSpecs(productDetails);
        }
        return getOrderCarSpecs(productDetails);
    } else if (productDetails.category === 'Van') {
        if (productDetails.availability === 'Used' || productDetails.availability === 'Engros') {
            return getUsedVanSpecs(productDetails);
        }

        return getOrderVanSpecs(productDetails);
    }

    return [];
};
