import { hesselViewModels } from '../../../view-models';
import { Phyron } from '../../../view-models/vehicle';

/**
 * For the selected color: If a matching resource was found, use it.
 * @param selectedProduct
 * @param selectedColor
 * @returns
 */
export function handleCarResources(
    selectedProduct: hesselViewModels.ProductDetails,
    selectedColor?: hesselViewModels.ProductColor,
    matchedResources?: Array<hesselViewModels.PdpResource>
): { resources: Array<hesselViewModels.PdpResource>; matchFound: boolean } {
    const phyronResource: Phyron[] = [];
    const phyronData = selectedProduct.phyronData;

    if (phyronData?.dataCampaign && phyronData?.referenceNumber) {
        const dataCampaign = phyronData.dataCampaign;
        const referenceNumber = phyronData.referenceNumber;
        phyronResource.push({
            referenceNumber: referenceNumber,
            dataCampaign: dataCampaign,
            type: 'phyron',
            url: `https://${dataCampaign}.cust.nl.phyron.com/repo/${referenceNumber}/1024x768.mp4`,
            thumbnailUrl: `https://${dataCampaign}.cust.nl.phyron.com/repo/${referenceNumber}/1024x768-thumb-play.jpg`,
            poster: `https://${dataCampaign}.cust.nl.phyron.com/repo/${referenceNumber}/1024x768.jpg`,
        });
    }

    if (!selectedColor) {
        return {
            resources: [...phyronResource, ...selectedProduct.resources],
            matchFound: false,
        };
    }

    const matchingResource = selectedProduct.resources.filter((x) => x.type === 'image' && x.colorCode === selectedColor.code);
    const youTubeVideos = selectedProduct.resources.filter((x) => x.type === 'youTube');
    const vehicleVideos = selectedProduct.vehicleType === 'Van' ? selectedProduct.resources.filter((x) => x.type === 'video') : [];

    if (matchingResource.length) {
        const interior = selectedProduct.resources.filter((x) => x.type === 'image' && x.colorCode === 'Interior');
        return {
            resources: [...phyronResource, ...matchingResource, ...interior, ...youTubeVideos, ...vehicleVideos],
            matchFound: true,
        };
    }

    // If no resources found, take the first one.
    const imageResource = selectedProduct.resources.find((x) => x.type === 'image');
    const firstAvailableColorCode = imageResource && imageResource.type === 'image' ? imageResource.colorCode : undefined;
    const firstAvailableResource = selectedProduct.resources.filter((x) => x.type === 'image' && x.colorCode === firstAvailableColorCode);

    return {
        resources: [...phyronResource, ...(matchedResources ?? firstAvailableResource)],
        matchFound: false,
    };
}
